let resumeData = {
    "imagebaseurl":"https://gouravkoirala.com",
    "name": "Gaurav Koirala",
    "role": "Software Developer",
    "linkedinId":"",
    "skypeid": "",
    "roleDescription": "I am originally from the Kathmandu, Nepal. I love to write code and enjoy playing the guitar on spare time.",
    "socialLinks":[
        {
          "name":"email",
          "url":"mailto:gauravkoirala0010@gmail.com",
          "className":"fa fa-envelope"
        }
      ],
    "aboutme":"I am from the Kathmandu Valley. I love to write code and enjoy playing the guitar on spare time. I'm always looking for opportunities to grow my skillset.",
    "website":"https://manish.dev",

  }
  
  export default resumeData